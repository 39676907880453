import * as $ from "jquery";
import { highlight_errors, notify } from "../helper/jquery.helper";
import { load_modal } from "../http/loader";
$(document).on('submit', '#upload_image', function (e) {
    var _this = this;
    e.preventDefault();
    var id = $(this).data('id');
    var form = new FormData();
    var image = document.querySelector('input[type="file"]');
    if (image.value !== '') {
        form.append('image', image.files[0]);
    }
    $.ajax({
        url: "/api/dispatches/".concat(id, "/images"),
        method: 'post',
        data: form,
        processData: false,
        contentType: false,
        success: function (response) {
            notify(response, $(_this), true);
            if (response.status === 200 && $(_this).data('open-modal')) {
                load_modal($(_this).data('open-modal'));
            }
        }, error: function (response) {
            notify(response.responseJSON, $(_this), false);
            highlight_errors(response.responseJSON);
        }
    });
});
