var reply = document.querySelector('input[name="reply_required"]');
if (reply) {
    reply.addEventListener('change', function (e) {
        var target = e.target;
        if (target.checked) {
            document.querySelector('#responses_container').classList.remove('d-none');
            document.querySelector('#sender_container').classList.add('d-none');
        }
        else {
            document.querySelector('#responses_container').classList.add('d-none');
            document.querySelector('#sender_container').classList.remove('d-none');
            var message = document.querySelector('#message');
            message.value = remove_response_1(message.value);
        }
    });
    document.querySelector('#responses_container').addEventListener('change', function (e) {
        var textarea = document.querySelector('#message');
        var target = e.target;
        var text = remove_response_1(textarea.value);
        if (target.value !== "") {
            text += ("\n" + target.value);
        }
        textarea.value = text;
    });
    var remove_response_1 = function (text) {
        var index = text.indexOf('\nBitte antworten');
        if (index !== -1) {
            return text.substring(0, index);
        }
        return text;
    };
}
