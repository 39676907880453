var _this = this;
import * as $ from "jquery";
import { highlight_errors, notify } from "../helper/jquery.helper";
import { load_modal, start_loading, stop_loading } from "./loader";
export var submit = function (method, url, data, notificationContainer, button, options, success) {
    if (options === void 0) { options = {
        redirect: null,
        reset: 0,
        reload: 0,
        openModal: null,
    }; }
    if (success === void 0) { success = null; }
    $(document).find('.is-valid').removeClass('is-valid');
    $(document).find('.is-invalid').removeClass('is-invalid');
    var text = start_loading(button);
    return $.ajax({
        method: method,
        url: url,
        data: data,
        success: function (response) {
            if (options.openModal) {
                load_modal(options.openModal).then(function () {
                    notify(response, $(document).find('#modal-container .modal-body'));
                });
            }
            else {
                notify(response, notificationContainer);
            }
            if (success !== null) {
                success();
            }
            if (options.redirect) {
                setTimeout(function () { return window.location.href = options.redirect; }, 1000);
            }
            if (options.reset === 1) {
                $(_this).trigger('reset');
            }
            if (options.reload === 1) {
                window.location.reload();
            }
        },
        error: function (response) {
            var status = response.responseJSON.status;
            if (status === 401) {
                window.location.href = '/';
            }
            notify(response.responseJSON, notificationContainer);
            highlight_errors(response.responseJSON);
        }
    }).always(function () {
        stop_loading(button, text);
    });
};
