var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as $ from "jquery";
import { submit } from "./http/forms";
import { load_confirm, load_modal } from "./http/loader";
import "../js/bootstrap.bundle";
import "./modules/messages.module";
import "./modules/list.module";
import "./modules/images.module";
import { notify } from "./helper/jquery.helper";
(function () {
    $(document).find('[data-toggle="popover"]').popover();
    $('.popover-dismiss').popover({
        trigger: 'focus'
    });
    $('.hamburger').on('click', function () {
        $(this).toggleClass('is-active');
        $('nav#main').toggleClass('is-active');
    });
    $(document).on('submit', '[data-action="submit"]', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $btn = $this.find('button[type="submit"]');
        var files = $this.find('input[type="file"]');
        submit($this.data('method'), $this.data('url'), $this.serialize(), $this, $btn, {
            redirect: $(this).data('redirect'),
            reload: $(this).data('reload'),
            reset: $(this).data('reset'),
            openModal: $(this).data('open-modal')
        }).done(function () {
            console.log('then...');
            files.each(function (index, element) {
                var input = $(element);
                var form = new FormData();
                form.append(input.attr('name'), input[0].files[0]);
                submit('POST', input.data('url'), form, $this, $btn);
            });
        });
    });
    $(document).on('submit', '[data-action="upload"]', function (e) {
        var _a;
        e.preventDefault();
        var form = new FormData();
        $(this).find('input').each(function (i, element) {
            var $element = $(element);
            form.append($element.attr('name'), $element.val());
        });
        submit($(this).data('method'), $(this).data('url'), $(this).serialize(), (_a = $(this).data('target')) !== null && _a !== void 0 ? _a : $(this), $(this).find('button[type="submit"]'), {
            redirect: $(this).data('redirect'),
            reload: $(this).data('reload'),
            reset: $(this).data('reset'),
            openModal: $(this).data('open-modal')
        });
    });
    $(document).on('click', '[data-load="modal"]', function () {
        load_modal($(this).data('url')).then(function () {
            $(document).find('[data-toggle="popover"]').popover();
        });
    });
    $(document).on('click', '[data-trigger="submit"]', function () {
        var form = $(document).find('#' + $(this).data('form'));
        submit(form.data('method'), form.data('url'), form.serialize(), form, $(this), {
            redirect: form.data('redirect'),
            reload: form.data('reload'),
            reset: form.data('reset'),
            openModal: form.data('open-modal')
        });
    });
    $(document).on('click', '[data-trigger="upload"]', function () {
        var _a;
        var form = $(document).find('#' + $(this).data('form'));
        var data = new FormData();
        form.find('input').each(function (i, element) {
            var $element = $(element);
            if ($element)
                data.append($element.attr('name'), $element.val());
        });
        submit(form.data('method'), form.data('url'), data, (_a = form.data('target')) !== null && _a !== void 0 ? _a : form, $(this), {
            redirect: form.data('redirect'),
            reload: form.data('reload'),
            reset: form.data('reset'),
            openModal: form.data('open-modal')
        });
    });
    $(document).on('click', '[data-trigger="confirm"]', function () {
        var _this = this;
        load_confirm($(this).data('title'), $(this).data('text')).then(function (modal) {
            var exec = function () {
                var _a;
                submit($(_this).data('method'), $(_this).data('url'), null, $((_a = $(_this).data('container')) !== null && _a !== void 0 ? _a : _this), $(_this), {
                    redirect: $(_this).data('redirect'),
                    reload: $(_this).data('reload'),
                    reset: $(_this).data('reset'),
                    openModal: $(_this).data('open-modal')
                }).then(function () {
                    modal.modal('hide');
                });
            };
            $(_this).data('open-modal') ? load_modal($(_this).data('open-modal')).then(exec) : exec();
        }).catch(function (modal) {
            if ($(_this).data('open-modal')) {
                load_modal($(_this).data('open-modal'));
            }
            else {
                modal.modal('hide');
            }
        });
    });
    $(document).on('click', '[data-trigger="delete"]', function () {
        var _this = this;
        load_confirm($(this).data('title'), $(this).data('text')).then(function (modal) {
            var container = $(_this).data('notification');
            var exec = function () {
                submit('DELETE', $(_this).data('url'), null, container ? $(container) : $('main > .container'), $(_this), {
                    redirect: $(_this).data('redirect'),
                    reload: $(_this).data('reload'),
                    reset: $(_this).data('reset'),
                    openModal: $(_this).data('open-modal')
                }, function () {
                    $(_this).closest('tr').remove();
                });
            };
            if ($(_this).data('open-modal')) {
                load_modal($(_this).data('open-modal')).then(exec);
            }
            else {
                exec();
                modal.modal('hide');
            }
        }).catch(function (modal) {
            if ($(_this).data('open-modal')) {
                load_modal($(_this).data('open-modal'));
            }
            else {
                modal.modal('hide');
            }
        });
    });
    $(document).on('click', '[data-trigger="reload"]', function () {
        window.location.reload();
    });
    $(document).on('change', 'input[type="file"]', function () {
        var file = this.files[0].name;
        $(this).parent().find('label').text(file);
    });
    $('#modal-container .modal').on('hidden.bs.modal', function (e) {
        $(this).html('');
    });
    $(document).on('click', '#copy_password', function () {
        var password = $(document).find('#new_password').val();
        navigator.clipboard.writeText(password).then(function () {
            notify({ status: 200, message: 'Das Passwort wurde kopiert', payload: null }, $('.alert-container'));
        });
    });
    $(document).on('click', '#send_sms', function () {
        var textarea = $(document).find('#message');
        textarea.prop('disabled', !textarea.prop('disabled'));
    });
    $(document).on('click', '#generate_password', function () {
        var lowerCase = Math.random().toString(36).slice(-10).toLowerCase().replace(/[^A-Z]/, '').slice(0, 5).split('');
        var upperCase = Math.random().toString(36).slice(-10).toUpperCase().replace(/[a-z]/, '').slice(0, 3).split('');
        var numbers = Math.random().toString(36).slice(-10).replace(/[^\d]/, '').slice(0, 1).split('');
        var specials = '@!.$?'.charAt(Math.floor(Math.random() * 4));
        var chars = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], lowerCase, true), [specials], false), upperCase, true), numbers, true);
        var password_input = $('#new_password');
        password_input.val(chars.sort(function () { return 0.5 - Math.random(); }).join(''));
        password_input.prop('disabled', false);
    });
    // auto logout after 30 minutes
    setTimeout(function () {
        window.location.href = '/logout';
    }, 1800000);
})();
