import * as $ from "jquery";
export var load_modal = function (url) {
    return new Promise(function (resolve) {
        var modal = $('#modal-container .modal');
        var isActive = modal.html().length > 0;
        $.get(url, function (response) {
            if (response.status === 200) {
                modal.html(response.payload.html);
                if (!isActive) {
                    modal.modal({
                        backdrop: 'static',
                    });
                }
                $(document).find('[data-toggle="popover"]').popover();
                resolve(modal);
            }
        });
    });
};
export var load_confirm = function (title, text) {
    return new Promise(function (resolve, reject) {
        load_modal("/modal/confirmation?title=".concat(title, "&text=").concat(text)).then(function (modal) {
            var buttons = $(document).find('[data-confirm]');
            buttons.on('click', function () {
                buttons.off('click');
                $(this).data('confirm') == 1 ? resolve(modal) : reject(modal);
            });
        });
    });
};
export var load_responses = function () {
    return new Promise(function (resolve, reject) {
        $.get('/api/sms/responses').then(function (response) {
            if (response.status === 200) {
                resolve(response.payload);
            }
            else {
                reject();
            }
        });
    });
};
export var start_loading = function (button) {
    var text = button.html();
    button.attr('disabled', 'disabled');
    button.html("<i class=\"fas fa-spinner fa-spin mr-2\"></i>");
    return text;
};
export var stop_loading = function (button, text) {
    button.removeAttr('disabled');
    button.html(text);
};
