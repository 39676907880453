import * as $ from "jquery";
export var notify = function (response, container, clear) {
    if (container === void 0) { container = $('body'); }
    if (clear === void 0) { clear = true; }
    if (clear) {
        container.find('.alert').remove();
    }
    var type, icon;
    switch (response.status) {
        case 200:
            type = 'alert-success';
            icon = 'fa-check-circle';
            break;
        case 202:
            type = 'alert-warning';
            icon = 'fa-warning';
            break;
        default:
            type = 'alert-danger';
            icon = 'fa-times-circle';
            break;
    }
    container.prepend("\n            <div class=\"alert alert-float ".concat(type, " d-block\">\n                <i class=\"fas ").concat(icon, " mr-2\"></i>\n                ").concat(response.message, "\n                <button class=\"close\" data-dismiss=\"alert\"><span>\u00D7</span></button>\n            </div>\n        "));
    if (response.status === 200) {
        setTimeout(function () { return $('.alert-success').hide(); }, 2000);
    }
};
export var highlight_errors = function (response) {
    if (response.payload.error_fields) {
        response.payload.error_fields.map(function (field) {
            $(document).find('#' + field).addClass('is-invalid');
        });
    }
};
