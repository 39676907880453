import * as $ from "jquery";
import { load_modal } from "../http/loader";
import { highlight_errors, notify } from "../helper/jquery.helper";
function load_update_modal(id) {
    return load_modal("/modal/lists/".concat(id, "/update"));
}
$(document).on('click', '[data-open="list"]', function () {
    var id = $(this).data('id');
    load_update_modal(id);
});
$(document).on('click', '#submit_create_list', function () {
    var form = $(document).find('#create_list');
    $.ajax({
        method: 'POST',
        url: '/api/lists',
        data: form.serialize(),
        success: function (response) {
            if (response.status === 200) {
                load_modal("/modal/lists/".concat(response.payload.id, "/update")).then(function () {
                    notify(response, $(document).find('#modal-container .modal-body'), false);
                });
            }
        },
        error: function (response) {
            notify(response.responseJSON, form, false);
            highlight_errors(response.responseJSON);
        }
    });
});
$(document).on('click', '#submit_update_list', function () {
    var id = $(this).data('id');
    var form = $(document).find('#update_list');
    $.ajax({
        method: 'PUT', url: "/api/lists/".concat(id), data: form.serialize(), success: function (response) {
            if (response.status === 200) {
                notify(response, form);
                var list = document.querySelector('input[name="excel"]');
                var option = document.querySelector('input[name="type"]:checked').value;
                if (list.value !== '') {
                    var formData = new FormData();
                    formData.append('excel', list.files[0]);
                    $.ajax({
                        method: 'POST',
                        url: "/api/lists/".concat(id, "/import?type=").concat(option),
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function (response) {
                            load_update_modal(id).then(function () {
                                notify(response, $(document).find('#modal-container .modal-body'), false);
                            });
                        },
                        error: function (response) {
                            notify(response.responseJSON, form, false);
                            highlight_errors(response.responseJSON);
                        }
                    });
                }
            }
        }
    });
});
